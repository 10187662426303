import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronRightOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { FormHelperText, IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
import { SmartCaptcha } from '@yandex/smart-captcha';
import MuiPhoneNumber from 'material-ui-phone-number';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { request } from 'src/utils/request';
import { routes } from 'src/utils/routes';
import { AuthLayout, Button } from '../../components';
import { getCurrentUserProfile, logout, registerAccount } from '../../store/actions/auth';
import { CAPTCHA_SITE_KEY, LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import { registrationValidationSchema } from '../../utils/formValidator';
import { createErrorNotification } from '../../utils/notifications';
import classes from './register.module.sass';
import NotifyModal from './NotifyModal';

const RegisterPage = () => {
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const transText = TEXT[lang];
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [token, setToken] = useState('');
  const [captchaError, setCaptchaError] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { fullname: '', phone: '', email: '', password: '', confirmPassword: '' },
    resolver: yupResolver(registrationValidationSchema(step, lang)),
    mode: 'onTouched',
  });

  const queryParams = qs.parse(location.search);

  const fetchAppInfo = async () => {
    const response = await request(`/debug/app-info`);
    return response.message;
  };
  const { isLoading: isLoadingAppInfo, data: appInfo } = useQuery(['fetchAppInfo'], fetchAppInfo);
  const enableCaptcha = CAPTCHA_SITE_KEY && appInfo?.enableCaptcha;

  const onSubmit = async (values) => {
    if (step === 1) {
      console.log(values);
      setStep(2);
      return;
    }
    if (enableCaptcha && !token) {
      setCaptchaError(true);
      return;
    }
    const res = await registerAccount(
      {
        ...values,
        captchaToken: token,
        invitationUuid: queryParams?.invitation_id,
        projectUuid: queryParams?.projectUuid,
        roleUuid: queryParams?.roleUuid,
      },
      !queryParams?.invitation_id
    );

    if (typeof res !== 'boolean' && res?.code === 400 && res.message.error === 'invalid invitation') {
      setShowNotifyModal(true);
      return;
    }
    console.log('currentProjectUuid1', searchParams.get('project_uuid'));
    if (res) {
      console.log('currentProjectUuid2', searchParams.get('project_uuid'));

      navigate(
        queryParams?.invitation_id || queryParams?.projectUuid
          ? routes.projectAccreadition.path.replace(':projectUuid', searchParams.get('project_uuid'))
          : routes.homepage.path
      );
    }
  };

  const checkInvitation = async (invitationUuid, redirectToLogin) => {
    try {
      const response = await request(`/check-invitation/${invitationUuid}`);
      const accessToken = response.message?.accessToken;
      const refreshToken = response.message?.accessToken;
      const projectUuid = response.message?.projectUuid;
      console.log('response');
      console.log(response);
      if (redirectToLogin) {
        navigate(routes.login.path + '?invitation_id=' + invitationUuid + '&project_uuid=' + projectUuid);
        return;
      }
      searchParams.set('project_uuid', projectUuid);
      searchParams.set('invitation_id', invitationUuid);
      setSearchParams(searchParams);
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        await getCurrentUserProfile(true, projectUuid);
      }
    } catch (error) {
      console.error(error);
      if (searchParams.has('invitation_id')) {
        searchParams.delete('invitation_id');
        setSearchParams(searchParams);
      }
      if (error?.code === 400 && error?.message?.error === 'invalid invitation') {
        setShowNotifyModal(true);
      }
    }
  };

  useEffect(() => {
    const invitationUuid = new URLSearchParams(location.search).get('invitation_id');
    const projectUuid = new URLSearchParams(location.search).get('projectUuid');
    const project_uuid = new URLSearchParams(location.search).get('project_uuid');
    const roleUuid = new URLSearchParams(location.search).get('roleUuid');
    const fromLogin = location.state?.fromLogin;
    const email = location.state?.email;

    if (email) {
      createErrorNotification(MESSAGES[lang].ERROR_MESSAGE_NON_EXIST_EMAIL);
      setValue('email', email);
    }

    if (location.state && location.state.error) {
      createErrorNotification(location.state.error);
    }

    if (projectUuid && roleUuid && !fromLogin) {
      navigate(routes.login.path + location.search);
    }

    if (invitationUuid) {
      //Проверка приглашение и добавление id проекта в query для последуюего перехода в проект
      checkInvitation(invitationUuid, !project_uuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 20, fontFamily: 'Lato' };

  return (
    <AuthLayout>
      <div className={classes.root}>
        <span className={classes.title} color="initial">
          {step === 1 ? transText.REGISTRATION : transText.CREATE_PASSWORD}
        </span>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {step === 1 ? (
            <>
              <TextField
                label={transText.FULLNAME}
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                variant="standard"
                {...register('fullname')}
                error={errors.fullname ? true : false}
                helperText={errors.fullname?.message}
              />

              <MuiPhoneNumber
                label={transText.TELEPHONE_COMPANY}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                name="phone"
                variant="standard"
                autoFormat={false}
                defaultCountry={'ru'}
                {...register('phone')}
                onChange={(value) => setValue('phone', value)}
                error={errors.phone ? true : false}
                helperText={errors.phone?.message}
              />

              <TextField
                label="E-mail"
                type="email"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                variant="standard"
                {...register('email')}
                error={errors.email ? true : false}
                helperText={errors.email?.message}
              />
            </>
          ) : (
            <>
              <TextField
                label={transText.PASSWORD}
                type={showPassword.password ? 'text' : 'password'}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                      >
                        {showPassword.password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                {...register('password')}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
              />

              <TextField
                label={transText.REPEAT_PASSWORD}
                type={showPassword.confirmPassword ? 'text' : 'password'}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })
                        }
                      >
                        {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                {...register('confirmPassword')}
                error={errors.confirmPassword ? true : false}
                helperText={errors.confirmPassword?.message}
              />
              {enableCaptcha && !isLoadingAppInfo && (
                <Stack>
                  <SmartCaptcha
                    sitekey={CAPTCHA_SITE_KEY}
                    language={lang === LOCALIZATION.en_US ? 'en' : 'ru'}
                    onSuccess={(token) => {
                      setToken(token);
                      setCaptchaError(false);
                    }}
                    onTokenExpired={() => {
                      setToken('');
                    }}
                  />
                  {captchaError && <FormHelperText error={true}>{MESSAGES[lang].CAPTCHA_ERROR}</FormHelperText>}
                </Stack>
              )}
            </>
          )}

          <Button
            type="submit"
            variant="contained"
            size="large"
            className={classes.submitButton}
            endIcon={<>{step === 1 && <ChevronRightOutlined />} </>}
          >
            {step === 1 ? transText.PROCEED : transText.READY}
          </Button>

          <Link
            href={`${routes.login.path + location.search}`}
            underline="none"
            color="#000"
            style={{ textAlign: 'center', fontSize: '1.25rem' }}
          >
            {transText.ALREADY_HAVE_ACCOUNT}
          </Link>
        </form>
      </div>
      <NotifyModal
        open={showNotifyModal}
        onClose={() => {
          setShowNotifyModal(false);
        }}
      />
    </AuthLayout>
  );
};

export default RegisterPage;
