import { isEqual } from 'lodash';
import * as Yup from 'yup';
import { LOCALIZATION, REGEX_E164_PHONE, REGEX_FULL_NAME } from './constant';

export const filterUnChangedFields = (baseObject = {}, newObject = {}) => {
  let clonedNewObject = { ...newObject };
  Object.keys(newObject).forEach((key) => {
    if (isEqual(newObject[key], baseObject[key])) {
      delete clonedNewObject[key];
    }
  });
  return clonedNewObject;
};

const VALIDATOR_MESSAGE = {
  [LOCALIZATION.ru_RU]: {
    FULL_NAME_IS_REQUIRED: 'Укажите полное имя',
    FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS: 'Полное имя должно состоять как минимум из двух слов',
    EMAIL_INVALID: 'Электронная почта недействительна',
    PLEASE_FILL_OUT_THIS_FIELD: 'Пожалуйста, заполните это поле',
    PASSWORD_MIN_SIX_CHARACTERS: 'Пароль должен быть не менее 6 символов',
    PASSWORD_DOES_NOT_MATCH: 'Пароль не подходит',
    PHONE_INVALID: 'Телефон недействителен',
    END_DATE_BEFORE_START_DATE: "End date can't be before Start date",
    REQUIRE_LENGTH_NUMBER: 'Требуется ${number} числа',
    THIS_FIELD_MUST_BE_GREATER_THAN_ZERO: 'Это поле должно быть больше нуля',
    MAXLENGTH: (number) => `Максимум  ${number} символов`,
  },
  [LOCALIZATION.en_US]: {
    FULL_NAME_IS_REQUIRED: 'Full name is required',
    FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS: 'Full name must have at least two words',
    EMAIL_INVALID: 'Email is invalid',
    PLEASE_FILL_OUT_THIS_FIELD: 'Please fill out this field',
    PASSWORD_MIN_SIX_CHARACTERS: 'Password must be at least 6 characters',
    PASSWORD_DOES_NOT_MATCH: 'Password does not match',
    PHONE_INVALID: 'Phone is invalid',
    END_DATE_BEFORE_START_DATE: "End date can't be before Start date",
    REQUIRE_LENGTH_NUMBER: 'Required ${number} numbers',
    THIS_FIELD_MUST_BE_GREATER_THAN_ZERO: 'This field must be greater than zero',
    MAXLENGTH: (number) => `MAX  ${number} symbols`,
  },
};

export const getValidatorMessage = (lang = LOCALIZATION.ru_RU) => VALIDATOR_MESSAGE[lang];

export const selfRegistrationValidationSchema = (lang = LOCALIZATION.ru_RU) => {
  return Yup.object().shape({
    roleUuid: Yup.string().trim().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
  });
};

export const registrationValidationSchema = (step = 1, lang = LOCALIZATION.ru_RU) => {
  if (step === 1) {
    return Yup.object().shape({
      fullname: Yup.string()
        .trim()
        .required(VALIDATOR_MESSAGE[lang].FULL_NAME_IS_REQUIRED)
        .matches(REGEX_FULL_NAME, VALIDATOR_MESSAGE[lang].FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
      email: Yup.string()
        .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
        .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    });
  }
  return Yup.object().shape({
    password: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .min(6, VALIDATOR_MESSAGE[lang].PASSWORD_MIN_SIX_CHARACTERS),
    confirmPassword: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .min(6, VALIDATOR_MESSAGE[lang].PASSWORD_MIN_SIX_CHARACTERS)
      .oneOf([Yup.ref('password')], VALIDATOR_MESSAGE[lang].PASSWORD_DOES_NOT_MATCH),
  });
};

export const loginValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    email: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    password: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .min(6, VALIDATOR_MESSAGE[lang].PASSWORD_MIN_SIX_CHARACTERS),
  });

export const loginWithoutPasswordValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    email: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
  });

export const addMemberValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    email: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    fullName: Yup.string()
      .trim()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_FULL_NAME, VALIDATOR_MESSAGE[lang].FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
    roleUuid: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
  });

export const profileValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    fullname: Yup.string()
      .trim()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_FULL_NAME, VALIDATOR_MESSAGE[lang].FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
    city: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    gender: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    phone: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_E164_PHONE, VALIDATOR_MESSAGE[lang].PHONE_INVALID)
      .max(14, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
  });

export const passportValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    nationality: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    document_number: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    birth_date: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    issued_by: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
  });

export const userValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    fullname: Yup.string()
      .trim()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_FULL_NAME, VALIDATOR_MESSAGE[lang].FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
    city: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    companyName: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    phone: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_E164_PHONE, VALIDATOR_MESSAGE[lang].PHONE_INVALID)
      .max(14, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
    email: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    legalFormCountry: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    legalFormCity: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    legalFormTel: Yup.string().max(14, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
    extentCommercialDecisions: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    scopeCompany: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    typeActivityCompany: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    interestedSections: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    purposeVisiting: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    // amountPotentialPurchases: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    howToKnow: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    // blocksCongressProgram: Yup.array().min(1, VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
  });

export const userExpertValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    lastName: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    firstName: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    city: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    companyName: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    email: Yup.string().email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    phone: Yup.string().max(14, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
    legalFormCountry: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    legalFormCity: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    legalFormTel: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .max(14, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
  });

export const changePasswordValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    oldPassword: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .min(6, VALIDATOR_MESSAGE[lang].PASSWORD_MIN_SIX_CHARACTERS),
    newPassword: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .min(6, VALIDATOR_MESSAGE[lang].PASSWORD_MIN_SIX_CHARACTERS),
    confirmPassword: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .min(6, VALIDATOR_MESSAGE[lang].PASSWORD_MIN_SIX_CHARACTERS)
      .oneOf([Yup.ref('newPassword')], VALIDATOR_MESSAGE[lang].PASSWORD_DOES_NOT_MATCH),
  });

export const companyValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    name: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    fullName: Yup.string()
      .trim()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_FULL_NAME, VALIDATOR_MESSAGE[lang].FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
    extLegalAddress: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    countryOfRegistration: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    phone: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_E164_PHONE, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
    webAddress: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    contactPerson: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    email: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    companyType: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    kpp: Yup.string().length(9, VALIDATOR_MESSAGE[lang].REQUIRE_LENGTH_NUMBER?.replace('${number}', 9)),
    inn: Yup.string().length(10, VALIDATOR_MESSAGE[lang].REQUIRE_LENGTH_NUMBER?.replace('${number}', 10)),
  });

export const vizitsValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    name: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    country: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    region: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    address: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    telephone: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_E164_PHONE, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
    email: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    web: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    scope: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    services: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    description: Yup.string().max(600, VALIDATOR_MESSAGE[lang].MAXLENGTH(600)),
  });

export const membersVizitsValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    telephone: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_E164_PHONE, VALIDATOR_MESSAGE[lang].PHONE_INVALID),
    email: Yup.string()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .email(VALIDATOR_MESSAGE[lang].EMAIL_INVALID),
    fullName: Yup.string()
      .trim()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .matches(REGEX_FULL_NAME, VALIDATOR_MESSAGE[lang].FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
  });

export const vizitSearchValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    since: Yup.date(),
    till: Yup.date().min(Yup.ref('since'), VALIDATOR_MESSAGE[lang].END_DATE_BEFORE_START_DATE),
  });

export const ScheduleValidationSchema = (lang = LOCALIZATION.ru_RU) =>
  Yup.object().shape({
    peopleCount: Yup.number().positive(VALIDATOR_MESSAGE[lang].THIS_FIELD_MUST_BE_GREATER_THAN_ZERO),
    date: Yup.date()
      .required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD)
      .typeError(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
    location: Yup.string().required(VALIDATOR_MESSAGE[lang].PLEASE_FILL_OUT_THIS_FIELD),
  });
