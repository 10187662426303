import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextFieldLabel from 'src/components/mui/TextFieldLabel';
import Tooltip from 'src/components/mui/tooltip';
import { useDebounce } from 'use-debounce';
import { userAPIs } from '../../../services';
import { LOCALIZATION, MESSAGES, TEXT } from '../../../utils/constant';
import { filterUnChangedFields, vizitsValidationSchema } from '../../../utils/formValidator';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import classes from './business-card.module.sass';

let isTyping;
function CompanyForm({ projectUuid, vizits, setVizits, uploadedLogo, setRequireLogo, setMembersVizits, readOnly }) {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const countries = useSelector((state) => state.app.countries);
  const industries = useSelector((state) => state.app.industries);
  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state.auth.company);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        name: vizits?.name || company?.name || user?.properties?.companyName,
        country: vizits?.country || 643,
        region: vizits?.region || user?.properties?.legalFormCity,
        address: vizits?.address || user?.city || company?.extLegalAddress,
        telephone: vizits?.telephone || user?.legalFormTel || company?.phone,
        email: vizits?.email || company?.email || user?.email,
        web: vizits?.web || company?.webAddress || user?.properties?.legalFormWeb,
        scope: vizits?.scope,
        services: vizits?.services,
        description: vizits?.description,
        industry_id: vizits?.industry_id,
      }),
      [vizits]
    ),
    resolver: yupResolver(vizitsValidationSchema(lang)),
  });

  const [focusingField, setFocusingField] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const nameRef = useRef(null);
  const extLegalAddressRef = useRef(null);

  const getSuggestion = async (query, field = '', isAddress) => {
    if (isSearching) return;
    try {
      setIsSearching(true);
      const res = isAddress ? await userAPIs.getSuggestionAddress(query) : await userAPIs.getSuggestionCompany(query);
      setSuggestions(res.message || []);
      setFocusingField(field);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearching(false);
    }
  };

  const renderSuggestion = useCallback(
    (field, ref, isAddress) => {
      const handleSelect = (suggestion) => {
        setFocusingField('');
        if (isAddress) {
          suggestion && setValue('address', suggestion);
          suggestion && setValue('region', suggestion.split(',')?.[0]);
        } else {
          suggestion.fullName && setValue('name', suggestion.shortName);
          suggestion.emails?.[0] && setValue('email', suggestion.emails?.[0]);
          suggestion.phones?.[0] && setValue('phone', suggestion.phones?.[0]);
          suggestion.city && setValue('region', suggestion.city);
          suggestion.address && setValue('address', suggestion.address);
        }
      };

      return (
        <Popper
          open={focusingField === field}
          anchorEl={ref.current}
          placement="bottom-start"
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className={classes.suggestions}>
                <ClickAwayListener onClickAway={() => setFocusingField('')}>
                  <MenuList
                    sx={{
                      maxWidth: '50vw',
                      maxHeight: '450px',
                      overflow: 'scroll',
                    }}
                  >
                    {suggestions.map((suggest, i) => (
                      <MenuItem key={i} onClick={() => handleSelect(suggest)}>
                        {isAddress ? `${suggest}` : `${suggest.shortName} - ${suggest.managerName}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      );
    },
    [suggestions, focusingField]
  );

  const handleChangeTextField = (e, field, isAddress) => {
    const value = e.target.value;
    setValue(field, value);
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      getSuggestion(value, field, isAddress);
    }, 1000);
  };

  const fetchUserVizitsData = async () => {
    try {
      const membersVizitsRes = await userAPIs.getMembersVizitsProject(projectUuid);
      setMembersVizits(membersVizitsRes.message);
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (values) => {
    // if (!vizits?.logo && !uploadedLogo) return setRequireLogo(true);
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      const newValues = { ...values, country: parseInt(values.country) };
      const formData = new FormData();
      if (!vizits) {
        if (uploadedLogo) {
          formData.append('fileDocument', uploadedLogo, uploadedLogo?.name);
        }
        formData.append('vizit', JSON.stringify(newValues));
      }

      let res = {};
      if (vizits) {
        const body = filterUnChangedFields({ ...vizits, country: parseInt(vizits.country) }, newValues);
        res = await userAPIs.patchUserVizit(projectUuid, body);
      } else {
        res = await userAPIs.createVizit(projectUuid, formData);
      }
      setVizits(res.message);
      createNotification(MESSAGES[lang].SAVE_ANKETAS_SUCCESSFULLY);
      setIsChanged(false);
      if (!vizits) {
        await fetchUserVizitsData();
      }
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    reset(vizits);
    const values = getValues();
    setIsChanged(Object.keys(values).some((key) => vizits[key] !== values[key]));
  };

  useEffect(() => {
    const subscription = watch(() => {
      const values = getValues();
      setIsChanged(Object.keys(values).some((key) => vizits?.[key] !== values?.[key]));
    });
    return () => subscription.unsubscribe();
  }, [watch, vizits]);

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 17, fontFamily: 'Lato' };
  const isLocked = readOnly;

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Stack gap="8px">
            <div>
              <TextField
                required
                label={TEXT[lang].COMPANY_NAME}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    '& .MuiInputLabel-asterisk': {
                      color: 'red',
                    },
                  },
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                sx={{ width: '100%' }}
                inputRef={nameRef}
                variant="standard"
                defaultValue={getValues('name')}
                {...register('name')}
                onChange={(e) => handleChangeTextField(e, 'name')}
                error={errors.name ? true : false}
                helperText={errors.name?.message ? errors.name?.message : TEXT[lang].VISIT_PUBLICITY_HELPER}
                disabled={isLocked}
              />
              {renderSuggestion('name', nameRef)}
            </div>

            <MuiPhoneNumber
              required
              label={TEXT[lang].TELEPHONE_VIZIT}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                },
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              name="telephone"
              variant="standard"
              autoFormat={false}
              defaultCountry={'ru'}
              {...register('telephone')}
              value={getValues('telephone')}
              onChange={(value) => setValue('telephone', value)}
              error={errors.telephone ? true : false}
              helperText={errors.telephone?.message}
              disabled={isLocked}
            />

            <FormControl margin="normal" error={errors.country ? true : false} disabled={isLocked}>
              <InputLabel
                required
                shrink={true}
                variant="standard"
                sx={{
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                  fontSize: 17,
                }}
              >
                {TEXT[lang].COUNTRY_COMPANY}
              </InputLabel>

              <Select
                variant="standard"
                margin="dense"
                defaultValue={getValues('country')}
                {...register('country')}
                style={inputStyle}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.localization?.[lang]?.name}
                    {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                  </MenuItem>
                ))}
              </Select>
              {errors.country && (
                <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                  {errors.country.message}
                </FormHelperText>
              )}
            </FormControl>

            <TextField
              required
              label={TEXT[lang].REGION}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                },
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              variant="standard"
              defaultValue={getValues('region')}
              {...register('region')}
              error={errors.region ? true : false}
              helperText={errors.region?.message}
              disabled={isLocked}
            />
            <div>
              <TextField
                required
                label={TEXT[lang].ADDRESS_COMPANY}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    '& .MuiInputLabel-asterisk': {
                      color: 'red',
                    },
                  },
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                inputRef={extLegalAddressRef}
                sx={{ width: '100%' }}
                variant="standard"
                defaultValue={getValues('address')}
                {...register('address')}
                onChange={(e) => handleChangeTextField(e, 'address', true)}
                error={errors.address ? true : false}
                helperText={errors.address?.message}
                disabled={isLocked}
              />
              {renderSuggestion('address', extLegalAddressRef, true)}
            </div>
            <FormControl margin="normal" error={errors.industry ? true : false} disabled={isLocked}>
              <InputLabel
                required
                shrink={true}
                variant="standard"
                sx={{
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                  fontSize: 17,
                }}
              >
                {TEXT[lang]?.INDUSTRY}
              </InputLabel>

              <Select
                variant="standard"
                required
                margin="dense"
                defaultValue={getValues('industry_id')}
                {...register('industry_id')}
                style={inputStyle}
                allowClear={true}
              >
                <MenuItem value="">Не указывать</MenuItem>
                {industries.map((industry) => (
                  <MenuItem key={industry.id} value={industry.id}>
                    {industry.localization?.[lang]?.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.industry_id && (
                <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                  {errors.industry_id.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack gap="8px">
            <TextField
              required
              label={TEXT[lang].WEB_ADDRESS}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                },
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              variant="standard"
              defaultValue={getValues('web')}
              {...register('web')}
              error={errors.web ? true : false}
              helperText={errors.web?.message}
              disabled={isLocked}
            />
            <TextField
              required
              margin="normal"
              label={TEXT[lang].EMAIL_VIZIT}
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                },
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              variant="standard"
              {...register('email')}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              disabled={isLocked}
            />
            <TextField
              required
              label={TEXT[lang].SCOPE_OF_COMPANY}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                },
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              variant="standard"
              defaultValue={getValues('scope')}
              {...register('scope')}
              error={errors.scope ? true : false}
              helperText={errors.scope?.message}
              disabled={isLocked}
            />
            <TextField
              required
              label={TEXT[lang].OFFERED_PRODUCT_OR_SERVICES}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                sx: {
                  '& .MuiInputLabel-asterisk': {
                    color: 'red',
                  },
                },
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              variant="standard"
              defaultValue={getValues('services')}
              {...register('services')}
              error={errors.services ? true : false}
              helperText={errors.services?.message}
              disabled={isLocked}
            />
            <TextField
              label=""
              multiline={true}
              minRows={6}
              margin="normal"
              // InputProps={{
              //   style: inputStyle,
              // }}
              placeholder={TEXT[lang].PLEASE_TELL_ABOUT_COMPANY}
              helperText={errors.description?.message ? errors.description?.message : TEXT[lang].VISIT_PUBLICITY_HELPER}
              defaultValue={getValues('description')}
              {...register('description')}
              disabled={isLocked}
            />
          </Stack>
        </Grid>
      </Grid>

      {!isLocked && (
        <Grid container spacing={2} style={{ marginTop: 8, justifyContent: 'center' }}>
          <Grid item xs={12} sm={12} md={6} className={classes.button} style={{ justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              className={classes.submitButton}
              variant="contained"
              size="large"
              disabled={isSubmitting || !isChanged || isLocked}
            >
              {TEXT[lang].SAVE_CHANGES}
            </Button>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} className={classes.button} style={{ justifyContent: 'flex-start' }}>
          <Button
            className={classes.secondaryButton}
            variant="contained"
            size="large"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {TEXT[lang].CANCEL}
          </Button>
        </Grid> */}
        </Grid>
      )}
    </form>
  );
}

export default CompanyForm;
