import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getLocalizationValue } from 'src/utils/common';
import { CONSENT_PERSONAL_DATA_NAME, INTERNAL_DOCUMENTS, LOCALIZATION } from '../../utils/constant';
import DatePicker from '../date-picker';
import { SelectHookForm } from '../mui/select';
import AnketaUploadField from './AnketaUploadField';
import { useMemo } from 'react';
import AnketaPersonalField from './AnketaPersonalField';

const filter = createFilterOptions();

const customTextField = {
  '& > label': {
    position: 'relative',
    whiteSpace: 'normal',
    transform: 'none',
    fontSize: '0.75rem',
  },
  '& .MuiInput-root': {
    marginTop: 0,
  },
};

export const INPUT_TYPES = {
  text: {
    type: 'text',
    defaultValue: '',
    renderInput: ({ label, name, register, required, disabled }) => (
      <TextField
        required={required}
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant="standard"
        margin="normal"
        label={label}
        {...register(name)}
        disabled={disabled}
        sx={{ ...customTextField }}
      />
    ),
  },
  long_text: {
    type: 'long_text',
    defaultValue: '',
    renderInput: ({ label, name, register, error, required, disabled }) => (
      <TextField
        required={required}
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        InputLabelProps={{ shrink: true }}
        variant="standard"
        margin="normal"
        label={label}
        {...register(name, { maxLength: 256 })}
        error={error}
        helperText={error ? 'Max length is 256 characters' : null}
        disabled={disabled}
        sx={{ ...customTextField }}
      />
    ),
  },
  email: {
    type: 'email',
    defaultValue: '',
    renderInput: ({ label, name, register, required, error, helperText, disabled }) => (
      <TextField
        required={required}
        fullWidth
        type="email"
        InputLabelProps={{ shrink: true }}
        variant="standard"
        margin="normal"
        label={label}
        {...register(name)}
        error={error}
        helperText={helperText}
        disabled={disabled}
        sx={{ ...customTextField }}
      />
    ),
  },
  telephone: {
    type: 'telephone',
    defaultValue: '',
    renderInput: ({ label, name, control, required, error, helperText, disabled }) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiPhoneNumber
            required={required}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            name="telephone"
            variant="standard"
            autoFormat={false}
            defaultCountry={'ru'}
            label={label}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            error={error}
            helperText={helperText}
            disabled={disabled}
            sx={{ ...customTextField }}
          />
        )}
      />
    ),
  },
  number: {
    type: 'number',
    defaultValue: '',
    renderInput: ({ label, name, register, required, error, helperText, disabled }) => (
      <TextField
        required={required}
        fullWidth
        type="number"
        InputLabelProps={{ shrink: true }}
        variant="standard"
        margin="normal"
        label={label}
        {...register(name)}
        error={error}
        helperText={helperText}
        disabled={disabled}
        sx={{ ...customTextField }}
      />
    ),
  },
  url: {
    type: 'url',
    defaultValue: '',
    renderInput: ({ label, name, register, required, error, helperText, disabled }) => (
      <TextField
        required={required}
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant="standard"
        margin="normal"
        label={label}
        {...register(name)}
        error={error}
        helperText={helperText}
        disabled={disabled}
        sx={{ ...customTextField }}
      />
    ),
  },
  yes_no: {
    type: 'yes_no',
    defaultValue: false,
    renderInput: ({
      control,
      name,
      error,
      helperText,
      label,
      disabled,
      required,
      isConsentPersonalDataField,
      projectUuid,
      ...props
    }) => {
      return (
        <FormControl error={true}>
          <FormControlLabel
            required={required}
            control={
              <Controller
                name={name}
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={!!field.value}
                        disabled={disabled}
                        sx={{ color: (theme) => error && theme.palette.error.main }}
                      />
                      {/* {required && <span style={{ color: 'red', marginRight: '4px' }}>*</span>} */}
                    </>
                  );
                }}
              />
            }
            label={
              <Stack direction="row" alignItems="center" sx={{ color: (theme) => error && theme.palette.error.main }}>
                {isConsentPersonalDataField ? (
                  <AnketaPersonalField projectUuid={projectUuid} />
                ) : (
                  <>
                    {label.includes('Согласие обработки персональных данных') ? (
                      <Link href={INTERNAL_DOCUMENTS.AGREEMENT} target="_blank" sx={{ textDecoration: 'underline' }}>
                        {label}
                      </Link>
                    ) : (
                      <span>{label}</span>
                    )}
                  </>
                )}
              </Stack>
            }
            disabled={disabled}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      );
    },
  },
  checkboxes: {
    type: 'checkboxes',
    defaultValue: [],
    renderInput: ({
      name,
      label,
      options,
      optionsByLanguage,
      control,
      required,
      error,
      helperText,
      disabled,
      ...props
    }) => (
      <FormControl required={required} error={error} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) =>
            options.map((option, index) => (
              <FormControlLabel
                key={`${name}-${index}`}
                control={
                  <Checkbox
                    {...field}
                    value={option}
                    checked={(field.value || []).includes(option)}
                    onChange={(e) => {
                      const newValue = [...(field.value || [])];
                      if (e.target.checked) {
                        newValue.push(option);
                      } else {
                        newValue.splice(newValue.indexOf(option), 1);
                      }
                      field.onChange(newValue);
                    }}
                  />
                }
                label={optionsByLanguage[index]}
              />
            ))
          }
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    ),
  },
  popup_list: {
    type: 'popup_list',
    defaultValue: '',
    renderInput: ({
      name,
      label,
      options,
      optionsByLanguage,
      control,
      required,
      error,
      helperText,
      disabled,
      ...props
    }) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectHookForm
            required={required}
            label={label}
            selectProps={{ value: field.value, onChange: field.onChange }}
            error={error}
            errorComponent={<>{error && <FormHelperText error={true}>{helperText}</FormHelperText>}</>}
            disabled={disabled}
            sx={{ ...customTextField }}
          >
            {options.map((option, i) => (
              <MenuItem key={`${name}-${i}`} value={option}>
                <>{optionsByLanguage[i]}</>
              </MenuItem>
            ))}
          </SelectHookForm>
        )}
      />
    ),
  },
  popup_list_checkboxes: {
    type: 'popup_list_checkboxes',
    defaultValue: [],
    renderInput: ({
      name,
      label,
      options,
      optionsByLanguage,
      control,
      required,
      error,
      helperText,
      disabled,
      ...props
    }) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectHookForm
            required={required}
            label={label}
            multiple={true}
            selectProps={{ value: field.value, onChange: field.onChange }}
            errorComponent={<>{error && <FormHelperText error={true}>{helperText}</FormHelperText>}</>}
            disabled={disabled}
            sx={{ ...customTextField }}
          >
            {options.map((option, i) => (
              <MenuItem key={`${name}-${i}`} value={option}>
                <Checkbox size="small" checked={(field.value || []).includes(option)} />
                <ListItemText primary={optionsByLanguage[i]} />
              </MenuItem>
            ))}
          </SelectHookForm>
        )}
      />
    ),
  },
  radio_buttons: {
    type: 'radio_buttons',
    defaultValue: '',
    renderInput: ({
      name,
      control,
      options,
      optionsByLanguage,
      label,
      register,
      required,
      error,
      helperText,
      disabled,
      ...props
    }) => (
      <FormControl required={required} error={error} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <RadioGroup
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
            >
              {options.map((option, i) => (
                <FormControlLabel
                  key={`${option}-${i}`}
                  value={option}
                  control={<Radio checked={field.value === option} />}
                  label={optionsByLanguage[i]}
                />
              ))}
            </RadioGroup>
          )}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    ),
  },
  upload_file: {
    type: 'upload_file',
    renderInput: (props) => (
      <FormControl margin="normal" disabled={props.disabled}>
        <FormLabel>{props.label}</FormLabel>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field }) => (
            <AnketaUploadField
              fieldControl={field}
              docUuid={field.value}
              field={props.field}
              disabled={props.disabled}
              user={props.user}
              name={props.name}
              isResizable={props.autoFillTemplate.photo?.includes(props.field?.fieldTemplateUuid)}
            />
          )}
        />
      </FormControl>
    ),
  },
  data: {
    type: 'data',
    defaultValue: '',
    renderInput: ({ name, control, required, disabled, ...props }) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            required={required}
            selected={field.value || ''}
            onChange={(date) => {
              field.onChange(date);
            }}
            dateFormat="dd/MM/yyyy"
            customInput={
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="normal"
                variant="standard"
                label={props.label}
                fullWidth={true}
                sx={{ ...customTextField }}
              />
            }
            disabled={disabled}
          />
        )}
      />
    ),
  },
  datetime: {
    type: 'datetime',
    defaultValue: '',
    renderInput: ({ name, control, required, disabled, ...props }) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            required={required}
            selected={field.value || ''}
            showTimeSelect
            timeIntervals={20}
            onChange={(date) => {
              field.onChange(date);
            }}
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy HH:mm"
            customInput={
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="normal"
                variant="standard"
                label={props.label}
                fullWidth={true}
                sx={{ ...customTextField }}
              />
            }
            disabled={disabled}
          />
        )}
      />
    ),
  },
  list_input: {
    type: 'list_input',
    defaultValue: [],
    renderInput: ({ name, label, control, options, optionsByLanguage, disabled, ...props }) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              {...field}
              multiple={true}
              value={field.value || []}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  field.onChange([...options, newValue]);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  field.onChange([...options, newValue.inputValue]);
                } else {
                  field.onChange(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.inputValue);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `"${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              handleHomeEndKeys
              options={options.map((o, i) => ({ inputValue: o, title: optionsByLanguage[i] }))}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              freeSolo
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputLabelProps={{ ...params?.InputLabelProps, shrink: true }}
                    InputProps={{
                      ...params?.InputProps,
                    }}
                    variant="standard"
                    label={
                      <span>
                        {props.required && <span style={{ color: 'red', padding: '0 2px' }}>*</span>}
                        {label}
                      </span>
                    }
                    error={props.error}
                    helperText={props.helperText}
                    sx={{ ...customTextField }}
                  />
                );
              }}
              disabled={disabled}
            />
          );
        }}
      />
    ),
  },
};

export function AnketaFields({
  field,
  user,
  register = () => {},
  control,
  errors,
  isLocked,
  countries = [],
  autoFillTemplate,
  projectUuid,
}) {
  const lang = useSelector((state) => state.app.lang);
  const fieldSettings = field.AnketaFieldSettings;
  const fieldControl = INPUT_TYPES[fieldSettings?.typeInput];

  let options = fieldSettings?.options || [];
  let optionsByLanguage = getLocalizationValue(fieldSettings, lang, 'options') || [];

  if (
    fieldSettings &&
    ['popup_list'].includes(fieldSettings.typeInput) &&
    fieldSettings.options?.find((option) => option === 'Все страны')
  ) {
    options = countries.map((item) => getLocalizationValue(item, LOCALIZATION.ru_RU, 'name'));
    optionsByLanguage = countries.map(
      (item) => getLocalizationValue(item, lang, 'name') || getLocalizationValue(item, LOCALIZATION.ru_RU, 'name')
    );
  }

  if (optionsByLanguage.length !== options.length) {
    optionsByLanguage = options.map((op, i) => (optionsByLanguage[i] ? optionsByLanguage[i] : op));
  }

  const label = fieldSettings?.localization?.[lang]?.name || fieldSettings?.name;
  const isConsentPersonalDataField = useMemo(() => {
    return (
      fieldSettings?.typeInput === 'yes_no' &&
      (fieldSettings?.name === CONSENT_PERSONAL_DATA_NAME ||
        fieldSettings?.localization?.ru_RU?.name === CONSENT_PERSONAL_DATA_NAME ||
        fieldSettings?.localization?.en_US?.name === CONSENT_PERSONAL_DATA_NAME)
    );
  }, [fieldSettings]);

  return (
    <>
      {fieldControl
        ? fieldControl.renderInput({
            user,
            label,
            options: options,
            optionsByLanguage: optionsByLanguage,
            required: field.required,
            name: field.uuid,
            register,
            control,
            fullWidth: true,
            error: errors?.[field.uuid] ? true : false,
            helperText: errors?.[field.uuid]?.message,
            field,
            disabled: isLocked,
            autoFillTemplate,
            isConsentPersonalDataField,
            projectUuid,
          })
        : null}
    </>
  );
}

export default AnketaFields;
